// ==================================================
//  モバイル判定イベント
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return intFrameWidth <= 767 ? true : false;
}

// ==================================================
//  アンカーリンクのスムーススクロール
// ==================================================
const anchors = document.querySelectorAll('a[href^="#"]');
const headerHeight = 0;
const urlHash = location.hash;

for (let i = 0; i < anchors.length; i++) {
  anchors[i].addEventListener("click", (e) => {
    e.preventDefault();

    const href = anchors[i].getAttribute("href");
    if (href !== "#top" && href !== "#") {
      const target = document.getElementById(href.replace("#", ""));
      const position = window.scrollY + target.getBoundingClientRect().top - headerHeight;
      window.scroll({
        top: position,
        behavior: "smooth",
      });
    } else {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  });
}

// ==================================================
//  アンカーリンクのページ遷移位置調整
// ==================================================
window.addEventListener("load", (event) => {
  const url = location.href;
  let headerHeight = 0;

  if (url.indexOf("#") != -1) {
    const anchor = url.split("#");
    const position = Math.floor(document.getElementById(anchor[anchor.length - 1]).getBoundingClientRect().top + window.scrollY) - headerHeight;
    window.scroll({
      top: position,
      behavior: "auto",
    });
  }
});

// ==================================================
//  ローディング
// ==================================================
document.addEventListener("DOMContentLoaded", () => {
  const loader = document.getElementById("js-loader");
  setTimeout(() => {
    loader.style.transition = "opacity 0.8s ease, visibility 0.8s ease";
    loader.style.visibility = "hidden";
    loader.style.opacity = 0;
  }, 600);
});
